import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from 'react-redux'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import MainBanner from '../components/MainBanner'
import Sidebar from "../components/homeMenu/sideBar"
import LastArticle from "../components/LastArticle"
import Articles from "../components/Articles"
import { toggleWhiteSidebar, setNews } from "../state/app"
import { InView } from 'react-intersection-observer'

const NewsArticle = ({ data, dispatch, location }) => {
    const { t } = useTranslation()
    const { language } = useI18next()

    var dataNews = t("news", { returnObjects: true })
    var dataGlobal = t("global", { returnObjects: true })
    var newsQuery = data.allWpPost.nodes

    // ATRIBUIR AS NEWS AO STATE NEWS (REDUX)
    useEffect(() => {
        dispatch(setNews(newsQuery))
    })

    // FILTRAR OS ARTICLES CONSOANTE A LINGUA SELECTIONADA
    const filteredArticles = newsQuery.filter(function (article) {
        let articleLanguage = ''

        article.tags.nodes.map(slug => (
            articleLanguage = slug.slug
        ))

        return articleLanguage === language
    })

    return (
        <Layout
            data={dataGlobal}
            location={location}
        >
            <Seo title="Notícias" />
            <Sidebar
                content={dataGlobal.sidebar}
                projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
                projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
            />
            <MainBanner
                data={dataNews.menu}
                white
            />
            <LastArticle
                title={dataNews.projects}
                article={filteredArticles[0]}
            />
            {/* CHANGE SIDE BAR COLOR TO WHITE */}
            <InView as="div" threshold={0.5} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
                <Articles articles={filteredArticles} />
            </InView>
        </Layout>
    )
}

export default connect(state => ({
    news: state.app.news
}), null)(NewsArticle)

export const pageQuery = graphql`
  query WordPressPostArchive($language: String!) {
    locales: allLocale(filter: {ns: {in: ["news", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    allWpPost(sort: { fields: [date], order: DESC }) {
        nodes {
        uri
        date(formatString: "dddd | DD MMMM YYYY", locale: "pt")
        title
        content
        excerpt
        categories {
            nodes {
              name
            }
        }
        tags {
            nodes {
                slug
            }
        }
        featuredImage {
            node {
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                            formats: [JPG]
                            quality: 100
                        )
                    }
                }
            }
        }
        }
    }
  }
`