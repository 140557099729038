import React, { useState } from "react"
import { media } from './Styles'
import styled from "styled-components"
import ArticleCard from './ArticleCard'
import { useI18next } from 'gatsby-plugin-react-i18next'

import more from "../images/nav/more.svg"


const Projetos = ({ articles }) => {
    const { language } = useI18next()

    const [itemsToShow, setItemsToShow] = useState(6)
    const [selectedCategory, setselectedCategory] = useState(null)

    // CREATE AN ARRAY WITH ALL ARTICLE CATEGORIES
    let category = []
    articles.map(article => category.push(article.categories.nodes[0].name))

    // DELETE DUPLICATED CATEGORIES
    let categories = [...new Set(category)]

    // -----

    // CREATE AN ARRAY WITH SELECTED CATEGORY ARTICLES
    let selectedArticles = []
    articles.map(article => selectedCategory === article.categories.nodes[0].name && selectedArticles.push(article))
    
    return (
        <StyledProjetos>
            <div className='filters-wrapper'>
                <div className='filters'>
                    {categories.slice(0, 4).map((category, key) => (
                        <div
                            className={'button-container' + (selectedCategory === category ? ' selected' : '')}
                            key={key}
                            onClick={() => setselectedCategory(category)}
                        >
                            <button>
                                {category}
                            </button>
                        </div>
                    ))}
                    <div
                        className={'button-container ' + (selectedCategory === null && 'selected')}
                        onClick={() => setselectedCategory(null)}
                    >
                        <button>
                            {language === 'pt' ? 'Todos' : 'All'}
                        </button>
                    </div>
                </div>
            </div>
            <div className='content-wrapper'>
                <div className='articles'>
                    {selectedArticles.length !== 0 ? // SE O ARRAY NÃO FOR EMPTY = TEM CATEGORIA (MOSTRA OS ARTIGOS DESSA CATEGORIA)
                        selectedArticles.slice(0, itemsToShow).map((content, i) => (
                            <ArticleCard content={content} i={i} />
                        ))
                    : // SE O ARRAY FOR EMPTY = MOSTRA TODOS
                        articles.slice(0, itemsToShow).map((content, i) => (
                            <ArticleCard content={content} i={i} />
                        ))
                    }
                </div>

                {itemsToShow < (selectedArticles.length !== 0 ? selectedArticles.length : articles.length) && // ONLY SHOW BUTTON WHEN THERE ARE MORE ITEMS TO SHOW
                    <div className='more'>
                        <button onClick={() => setItemsToShow(itemsToShow + 4)}>
                            <img src={more} alt="More" />
                        </button>
                    </div>
                }
            </div>
        </StyledProjetos>
    )
}

export default Projetos

const StyledProjetos = styled.div`
    position:relative;
    padding: clamp(50px,10vw,90px) 0 0 0;

    .filters-wrapper{
        background: linear-gradient(to bottom, #fff 50%, #2c2c2c 50%);

        .filters{
            margin: 0 7.3%;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            background-color:#ebebeb;

            ${media.m`
                grid-template-columns: repeat(2,1fr);
            `}

            .button-container{
                display:flex;
                
                button{
                    font-family:'Montserrat', serif;
                    font-weight:500;
                    text-transform:uppercase;
                    font-size:1.2rem;
                    width: 100%;
                    padding: 2rem 5%;
                }

                :hover{
                    background-color:#3b3b3b;
                    color:#ebebeb;
                }
            } 
            
            .selected{
                background-color:#3b3b3b;
                color:#ebebeb;
            }
        }
    }    

    .content-wrapper{
        background-color:#2c2c2c;
        padding: 0 0 clamp(50px,10vw,90px) 0;

        .articles{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 5rem 5%;
            margin: 0 7.3%;
            padding: clamp(50px,10vw,90px) 0;

            ${media.m`
                margin: unset;
                padding: clamp(50px,10vw,50px) 7.3%;
                gap: 2.5rem 5%;
                grid-template-columns: 1fr;
            `} 
        }

        .more{
            margin: 0 7.3%;

            button{
                margin:0 auto;

                img{
                    max-width:45px;
                    border-radius:50%;
                    transition: 0.3s;

                    :hover{
                        background: #ebebeb80;
                    }
                }
            }        
        }
    }
`
