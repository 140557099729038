import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useI18next } from 'gatsby-plugin-react-i18next'
import styled from "styled-components"
import { media } from './Styles'

import more from "../images/nav/more.svg"


const Article = ({ article, title }) => {
    const { language } = useI18next()

    return (
        <StyledArticle>
            <div className='wrapper'>
                <div className='news-image'>
                    {getImage(article?.featuredImage?.node?.localFile) &&
                        <GatsbyImage image={getImage(article?.featuredImage?.node?.localFile)} alt={article?.featuredImage?.node?.altText || ''} />
                    }
                </div>
                <section className='container'>
                    <div className='content'>
                        <h4>{title}</h4>
                        <h3 className={"title"}>
                            {article?.title}
                        </h3>
                        <Link to={"/noticias" + article?.uri}>
                            {language === 'pt' ? 'LER NOTÍCIA COMPLETA' : 'READ THIS ARTICLE'}
                            <img src={more} alt="More" />
                        </Link>
                    </div>
                </section>
            </div>
        </StyledArticle>
    )
}

export default Article

const StyledArticle = styled.div`
    margin: 0 7.3%;
    padding: clamp(50px,10vw,90px) 0;

    .wrapper{
        position:relative;
        display:flex;
        align-items:center;
        min-height: 70vh;

        ${media.xl`
            min-height: unset;
            flex-direction:column;
        `}

        .news-image{
            position: absolute;
            width: 60%;
            height: 100%;
            right: 0;
            top: 0;
            z-index:-1;

            ${media.xl`
                position:unset;
                width:100%;
            `}

            .gatsby-image-wrapper{
                height:100%;

                ${media.xl`
                    max-height:50vh;
                `}
            }            
        }

        .container{
            padding: 6rem 0;
            width: 50%;

            ${media.xl`
                padding:0;
                width:100%;
            `}

            .content{
                width:100%;
                text-align:left;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                justify-content: center;
                background-color: #212121F2;
                color:#fff;
                padding: 6rem 5%;
                min-height: 40vh;

                ${media.smarttv`
                    gap:0;
                `}

                ${media.xl`
                    padding:5rem 5%;
                    min-height:unset;
                `}

                ${media.m`
                    padding:3rem 5%;
                `}

                h4{
                    text-transform:uppercase;
                    font-size: 1.4rem;

                    ${media.s`
                        font-size: 1.2rem;
                    `}
                }

                .title{
                    text-transform:uppercase;
                    font-size: 2.4rem;
                    font-weight: 600;

                    ${media.smarttv`
                        margin:2rem 0;
                    `}

                    ${media.s`
                        font-size: 2rem;
                    `}
                }

                a{
                    text-transform:uppercase;
                    font-size: 1.3rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    ${media.smarttv`
                        gap:0;
                    `}

                    ${media.s`
                        font-size: 1.2rem;
                    `}

                    img{
                        max-width:40px;
                        border-radius:50%;
                        transition: 0.3s;

                        ${media.smarttv`
                            margin-left:1rem;
                        `}

                        :hover{
                            background: #ebebeb80;
                        }
                    }
                }
            }
        }        
    }
`