import React, { useState } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useI18next } from 'gatsby-plugin-react-i18next'
import { media } from './Styles'
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import styled from "styled-components"
import { CSSTransition } from 'react-transition-group'

import more from "../images/nav/more.svg"


const Projetos = ({ content, i }) => {
    const breakpoints = useBreakpoint()
    const { language } = useI18next()
    const [articleInfo, setArticleInfo] = useState(false)
    const [articleKey, setArticleKey] = useState(null)

    return (
        <StyledArticle>
                <Link
                    to={"/noticias" + content?.uri}
                    key={"article" + i}
                    className="item"
                    id={"article" + i}
                >
                    {getImage(content?.featuredImage?.node?.localFile) &&
                        <GatsbyImage 
                            image={getImage(content?.featuredImage?.node?.localFile)} 
                            alt={content?.featuredImage?.node?.altText || ''}
                            className='article-image'
                            style={{ height: `100%` }}  
                            onMouseEnter={() => { setArticleInfo(true); setArticleKey(i) }}
                        />
                    }
                    <CSSTransition in={(articleInfo && articleKey === i) || breakpoints.l } timeout={250} classNames="animation" unmountOnExit>
                        <div 
                            className="cardContent"
                            onMouseLeave={() => { setArticleInfo(false); setArticleKey(null) }}
                        >
                            <h3>{content.title}</h3>
                            <div className='more'>
                                <h4 className="cardBtn">{language === 'pt' ? 'LER NOTÍCIA COMPLETA' : 'READ THIS ARTICLE'}</h4>
                                <img src={more} alt="More" />
                            </div>
                        </div>
                    </CSSTransition>
                </Link>
        </StyledArticle>
    )
}

export default Projetos

const StyledArticle = styled.div`
    .item {
        position: relative;
        text-transform: uppercase;
        max-height:50vh;
        color:#fff;
        height:100%;
    }

    .cardContent {
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        gap:1rem;
        background-color: #2c2c2cB3;
        padding: 5%;
        text-align:center;

        h3{
            font-weight:600;
            max-width: 500px;
            font-size:1.9rem;

            ${media.xxl`
                font-size:1.5rem;
            `}

            ${media.xl`
                font-size:1.2rem;
            `}

            ${media.s`
                font-size:1rem;
            `}
        }

        .more{
            display: flex;
            gap: 1rem;
            align-items: center;

            h4{
                font-size:1.2rem;

                ${media.xxl`
                    font-size:1rem;
                `}
            }

            img{
                max-width:40px;
                border-radius:50%;
                transition: 0.3s;

                :hover{
                    background: #ebebeb80;
                }
            }
        } 
    }

    // ANIMAÇÕES
    .animation-enter {
    opacity: 0;
    }
    .animation-enter-active {
        opacity: 1;
        transition: opacity 250ms;
    }
    .animation-exit {
        opacity: 1;
    }
    .animation-exit-active {
        opacity: 0;
        transition: opacity 250ms;
    }
    
`
